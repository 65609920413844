<template>
        <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
                <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%);">
                    <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                        <div class="grid flex flex-column align-items-center">
                            <p class="text-5xl">🎉</p>
                            <span class="text-600 text-center">Survey telah berhasil disimpan</span>
                            <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2 text-center">Terimakasih sudah berpartisipasi dalam market test Gilus Harum✨</h1>
                            <h5 class="text-600 text-center">Jangan lupa untuk meminum/membeli Gilus Harum</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>